.sum-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 16px 0 8px 0;
  background: #000;
  color: #fff;
  margin-top: 30px;
  z-index: 1000;
}
